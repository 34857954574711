<style>
.reportrange-text {
  min-height: 40px !important;
}
</style>
<template>
  <vx-card title="Supplier Invoice">
    <div class="vx-row">
      <vs-tabs>
        <vs-tab label="OCR">
          <ocr></ocr>
        </vs-tab>
        <vs-tab label="Trade">
          <invoice-trade></invoice-trade>
        </vs-tab>
        <vs-tab label="Non Trade">
          <div class="tab-text">
            <non-trade></non-trade>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import InvoiceTrade from "./index-vendor-invoice.vue";
import NonTrade from "./../vendor_invoice_nt";
import Ocr from "./../vendor_invoice_processor_ocr";

export default {
  components: {
    InvoiceTrade,
    NonTrade,
    Ocr
  },
};
</script>