<template>
  <multiselect
    :multiple="multiple"
    :allowEmpty="allowEmpty"
    @search-change="handleSearch"
    :custom-label="customLabel ? customLabel : 'name'"
    :track-by="trackBy ? trackBy : 'name'"
    :disabled="disabled"
    :options="optionsData.length > 0 ? optionsData : (options !== undefined ? options : [])"
    :select-label="customSelectLabel"
    :deselect-label="customDeselectLabel"
    v-model="selected"
    :placeholder="placeholder ? placeholder : 'type to search'"
  >
    <template slot="option" slot-scope="{ option }">
      <span>{{ option.Code }} - {{ option.Name }}</span>
    </template>
    <template slot="singleLabel" slot-scope="{ option }">
      <span v-if="option">{{ option.Code }}  {{ option.Name }}</span>
  </template>
  </multiselect>
</template>
<script>

export default {
  components: {
  },
  props: {
      multiple: {
          type: Boolean,
          default: false,
      },
      disabled: {
          type: Boolean,
          default: false,
      },
      placeholder: {
          type: String
      },
      customLabel: {
            type: [String, Function],
        },
      label: {
          type: String,
      },
      baseUrl: {
          type: String,
      },
      allowEmpty:{
          type:Boolean,
          default:true,
      },
      trackBy: {
          type: String,
      },
      value: {
      },
      options: {
          required: false
      },
      status: {
          required: false
      },
      selectedItem: {
      }
  },
  data() {
      return {
          typedValue: "",
          timeOuts: null,
          optionsData: [],
          selected: this.value ? this.value : {},
          customSelectLabel:"",
          customDeselectLabel:""
      };
  },
  methods: {
      handleSearch(v) {
          this.typedValue = v;
          if (this.timeOuts) { clearTimeout(this.timeOuts) }
          this.timeOuts = setTimeout(this.get, 400);
      },
      get() {
          this.$http
              .get(this.baseUrl, {
                  params: {
                      order: "id",
                      sort: "asc",
                      query: this.typedValue,
                      search: this.typedValue,
                      status: this.status,
                      ids: this.selectedItem,
                      length: 10,
                  }
              })
              .then(resp => {
                  if (resp.code == 200) {
                      console.log('Option COA',resp.data.records)
                      console.log('SEARCH',this.typedValue)
                      this.optionsData = resp.data.records.map(record => {
                        if (record.coa) {
                        return {
                            ID: record.coa.ID,
                            Name: record.coa.Name,
                            Code: record.coa.Code,
                        };
                        } else {
                        return null; // Atau cara lain untuk menangani record tanpa coa
                        }
                    }).filter(record => record !== null);
                    //   this.optionsData = resp.data.records;
                      if (!this.value && resp.data.default) {
                          this.selected = resp.data.default;
                          this.$emit("selected", resp.data.default);
                      }
                  } else {
                      this.optionsData = [];
                      console.log(resp.data);
                  }
              });
      }

  },
  watch: {
      selected(v) {
          console.log('value selected sku', v)
          this.selected = v;
          this.$emit("selected", v);
      },
      value(v) {
          if (v) {
              this.selected = v
          }
      },
      selectedItem(v){
          if (v) {
              this.get()
          }
      }
      // body(v) {
      //     this.body = v;
      // },
  },
  mounted() {
      if (this.baseUrl != "" && this.baseUrl != undefined) {
          this.get();
      } else if (this.options.length > 0) {
          this.optionsData = this.options
      }
  }
};
</script>
<style>
.multiselect--above .multiselect__content-wrapper{
  bottom: auto !important;
  top: 100% !important;
}

</style>